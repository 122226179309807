import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import {useStyles} from './styled';

export const Logo = ({width = 128, height = 47, isLink = true}) => {
  const classes = useStyles();

  if (isLink) {
    return (
      <div className={classes.imageBlock}>
        <Link href='/' passHref>
          <img
            src='/images/imagesNext/logo-new.svg'
            alt='Buy RS Gold within 5Min! Best Site for Fast & Cheap Runescape Gold'
            width={width}
            height={height}
          />
        </Link>
      </div>
    );
  }

  return (
    <div className={classes.imageBlock}>
      <img
        src='/images/imagesNext/logo-new.svg'
        alt='Buy RS Gold within 5Min! Best Site for Fast & Cheap Runescape Gold'
        width={width}
        height={height}
      />
    </div>
  );
};

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};
