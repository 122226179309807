import {Button, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {ArrowDownIcon} from 'src/componentsNext/icons/arrows/ArrowDownIcon';
import {maybe} from 'src/core/utils';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

import {UserLevelAvatar} from 'src/views/HeaderNext/components/UserLevelAvatar';

const useStyles = makeStyles(theme => ({
  button: {minWidth: 152, maxWidth: 220, width: 'auto', textAlign: 'start'},
  textInfo: {
    display: 'grid',
    marginLeft: theme.spacing(1.25)
  },
  rotatedIcon: {
    transform: 'rotate(180deg)'
  },
  name: {
    color: theme.palette.common.white,
    ...extendedTypography.body2RegularDesktop,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  points: {
    color: theme.palette.secondary.main,
    ...extendedTypography.caption1Desktop,
    fontWeight: 600
  },
  endIcon: {
    color: theme.palette.secondary.main,
    marginBottom: 'auto'
  }
}));

export const ProfileButton = ({open, customer, onClick}) => {
  const classes = useStyles();

  return (
    <Button
      classes={{root: classes.button, endIcon: classes.endIcon}}
      onClick={onClick}
      endIcon={
        <ArrowDownIcon
          width={20}
          height={20}
          className={clsx(classes.icon, open && classes.rotatedIcon)}
        />
      }
    >
      <UserLevelAvatar customer={customer} />
      <div className={classes.textInfo}>
        <Typography
          title={customer?.fullName || customer?.email}
          className={classes.name}
        >
          {customer?.fullName || customer?.email}
        </Typography>
        <Typography className={classes.points}>
          {maybe(() => customer.loyaltyDetails.points, 0)} Points
        </Typography>
      </div>
    </Button>
  );
};

ProfileButton.propTypes = {
  customer: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
