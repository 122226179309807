import {ListItemIcon, MenuItem} from '@material-ui/core';
import PropTypes from 'prop-types';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import SettingsIcon from '@material-ui/icons/Settings';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useSnackbar} from 'notistack';
import {useMutation} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import {CheckoutCustomerDetachMutation} from 'src/views/Header/mutations';
import {CHECKOUT_KEY} from 'src/config';
import useStorefrontData from 'src/hooks/useStorefrontData';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import {LogOutIcon} from 'src/componentsNext/icons/actions/LogOutIcon';

const useStyles = makeStyles(theme => ({
  menuItem: {
    ...extendedTypography.body1MediumDesktop,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.colors.menuItem
    }
  },
  divider: {
    position: 'relative',
    width: '100%',
    height: 1,

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      height: 0.5,
      backgroundColor: theme.palette.colors.dividerGrey
    }
  },
  dividerMobile: {
    marginTop: theme.spacing(1)
  },
  link: {
    color: theme.palette.common.white
  },
  menuItemIcon: {
    minWidth: 20,
    marginRight: theme.spacing(1),
    color: 'inherit'
  },
  menuItemMobile: {
    minHeight: 42,
    padding: theme.spacing(1, 3),
    ...extendedTypography.body1MediumDesktop
  }
}));

// eslint-disable-next-line no-undef
const dashboardUrl = process.env.DASHBOARD_URL;

export const menuItems = [
  {title: 'Profile', link: '/account/loyalty', icon: PersonOutlineIcon},
  {title: 'Orders history', link: '/account/orders', icon: DescriptionIcon},
  {title: 'Rewards history', link: '/account/rewards', icon: StarBorderIcon},
  {title: 'Settings', link: '/account/settings', icon: SettingsIcon}
  // { title: 'Rewards history', link: '/account/rewards' }
];

export const ProfileMenu = ({
  customer,
  onDropdownClose,
  onCloseDrawer,
  isMobile = false
}) => {
  const classes = useStyles();
  const router = useRouter();
  const {enqueueSnackbar} = useSnackbar();

  const {handleLogout: onLogout} = useStorefrontData();
  const [checkoutCustomerDetach] = useMutation(CheckoutCustomerDetachMutation);

  // const handleRedirectToDashboard = () => window.location.replace(dashboardUrl);

  const handleLogout = () => {
    onLogout();
    // close dropdown
    onDropdownClose?.();
    onCloseDrawer?.();
    // show message
    enqueueSnackbar('Logout successful!', {variant: 'success'});

    const checkout = JSON.parse(localStorage.getItem(CHECKOUT_KEY));

    if (checkout) {
      // detach user from checkout if it exists
      // usecase: guest has been logged in on checkout page
      checkoutCustomerDetach({
        variables: {
          checkout: checkout.id
        }
      });
    }
  };

  return (
    <>
      {customer.isStaff && customer.is2faEnabled && (
        <MenuItem
          component={Link}
          href={dashboardUrl}
          rel='noopener noreferrer'
          target='_blank'
          className={isMobile ? classes.menuItemMobile : classes.menuItem}
          key='dashboard'
          // onClick={handleRedirectToDashboard}
          classes={{
            selected: classes.menuItemSelected
          }}
        >
          Dashboard
        </MenuItem>
      )}

      {menuItems.map(item => {
        return (
          <MenuItem
            key={item.link}
            component={Link}
            href={item.link}
            className={isMobile ? classes.menuItemMobile : classes.menuItem}
            selected={router.asPath === item.link}
            key={item.title}
            classes={{
              selected: classes.menuItemSelected
            }}
          >
            {item.title}
          </MenuItem>
        );
      })}
      <div
        className={clsx(classes.divider, isMobile && classes.dividerMobile)}
      />

      <MenuItem
        divider={false}
        className={isMobile ? classes.menuItemMobile : classes.menuItem}
        key='logout'
        onClick={handleLogout}
        //design is very inconsistent so we're adding quick hacks as usual :(
        style={{padding: isMobile ? '12px 24px' : '12px 16px'}}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <LogOutIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </>
  );
};

ProfileMenu.propTypes = {
  customer: PropTypes.object.isRequired,
  onDropdownClose: PropTypes.func,
  onCloseDrawer: PropTypes.func,
  isMobile: PropTypes.bool
};
