import {useRouter} from 'next/router';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {REGISTER_MODAL} from 'src/config';
import useModal from 'src/hooks/useModal';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

export const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 100,
    padding: theme.spacing(1.25, 4),
    ...extendedTypography.body2SemiboldDesktop
  }
}));

export const SignUpButton = ({className, /*, children*/ ...props}) => {
  const classes = useStyles();
  const {showModal} = useModal();
  const router = useRouter();
  const {signup} = router.query;

  useEffect(() => {
    if (signup) {
      showModal(REGISTER_MODAL);
    }
  }, [showModal, signup]);

  // if (isMobile) {
  //   return (
  //     <Button
  //       variant='outlined'
  //       color='secondary'
  //       classes={{root: clsx(classes.button, className)}}
  //       onClick={() => showModal(REGISTER_MODAL)}
  //     >
  //       SIGN UP
  //     </Button>
  //   );
  // }

  return (
    <Button
      variant='contained'
      color='secondary'
      classes={{root: clsx(classes.button, className)}}
      onClick={() => showModal(REGISTER_MODAL)}
      {...props}
    >
      SIGN UP
    </Button>
  );
};

SignUpButton.propTypes = {
  className: PropTypes.string
  // children: PropTypes.any
};
