import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  // root: {
  //   '& img': {
  //     maxWidth: '128px !important',
  //     [theme.breakpoints.down('sm')]: {
  //       maxWidth: '128px !important',
  //       width: '100%'
  //     }
  //   }
  // },
  imageBlock: {
    height: 'fit-content',
    '& img': {
      minWidth: 110,

      [theme.breakpoints.down('sm')]: {
        minWidth: 104
      }
    },
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      textAlign: 'center'
    }
  }
}));
