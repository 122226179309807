import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import useModal from '../../../../hooks/useModal';
import { LOGIN_MODAL, REGISTER_MODAL } from '../../../../config';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center'
  },
  question: {
    color: '#737879'
  },
  answer: {
    color: '#162340',
    fontWeight: 700
  },
  signin: {},
  button: {
    maxWidth: 309,
    width: '100%',
    height: 45,
    boxShadow: '1px 2px 3px rgba(20,34,65,0.75)',
    marginTop: 20,
    border: '1px solid #162340'
  },
  signInButton: {
    backgroundColor: theme.palette.secondary.main
  },
  signup: {},
  signUpButton: {
    backgroundColor: '#56ccf2'
  }
}));

const AuthLinksModal = () => {
  const classes = useStyles();
  const { showModal } = useModal();
  return (
    <div className={classes.root}>
      <Box className={classes.signin} mb={8}>
        <Typography className={classes.question}>
          Do you have an account?
        </Typography>
        <Typography className={classes.answer}>
          Log in to enjoy your benefits!
        </Typography>
        <Button
          className={clsx(classes.button, classes.signInButton)}
          onClick={() => showModal(LOGIN_MODAL)}
        >
          Log in
        </Button>
      </Box>
      <Box className={classes.signup}>
        <Typography className={classes.question}>Not a member yet?</Typography>
        <Typography className={classes.answer}>
          Register And Join our Loyalty Program
        </Typography>
        <Button
          className={clsx(classes.button, classes.signUpButton)}
          onClick={() => showModal(REGISTER_MODAL)}
        >
          Sign up
        </Button>
      </Box>
    </div>
  );
};

export default AuthLinksModal;
