import Image from 'next/image';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const useStyles = makeStyles(theme => ({
  avatarWrapper: {
    padding: theme.spacing(0.5),
    borderRadius: 6,

    '&.small': {width: 36, height: 36},
    '&.medium': {width: 40, height: 40},
    '&.big': {width: 48, height: 48}
  },
  avatarImage: {
    position: 'relative',

    '&.small': {width: 26, height: 26},
    '&.medium': {width: 28, height: 28},
    '&.big': {width: 36, height: 36}
  }
}));

export const UserLevelAvatar = ({
  customer,
  size = 'medium',
  bg = 'rgba(255, 255, 255, 0.05)'
}) => {
  const classes = useStyles();

  if (customer) {
    return (
      <div
        className={clsx(classes.avatarWrapper, size)}
        style={{backgroundColor: bg}}
      >
        <div className={clsx(classes.avatarImage, size)}>
          <Image
            src={customer.loyaltyDetails.currentLevel.image.url}
            alt={customer.loyaltyDetails.currentLevel.name}
            quantity={100}
            unoptimized
            layout='fill'
            objectFit='contain'
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Skeleton width={32} height={32} />
    </div>
  );
};

UserLevelAvatar.propTypes = {
  customer: PropTypes.shape({}),
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  bg: PropTypes.string
};
