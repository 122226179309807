import {Button, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {maybe} from 'src/core/utils';
import {PageSlugs} from 'src/core/urls';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import {PointsStarIcon} from 'src/componentsNext/icons/PointsStarIcon';

import {POPPER_WIDTH} from './DesktopMenu/ProfileInfoWithDropdown/ProfileInfoWithDropdown';

import {UserLevelAvatar} from 'src/views/HeaderNext/components/UserLevelAvatar';

export const useStyles = makeStyles(theme => ({
  profileInfo: {
    display: 'grid'
  },
  firstRow: {
    width: POPPER_WIDTH,
    position: 'relative',
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2, 1.5, 2),

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      height: 0.5,
      backgroundColor: theme.palette.colors.dividerGrey
    }
  },
  firstRowText: {overflow: 'hidden'},
  email: {
    ...extendedTypography.body2RegularDesktop,
    lineHeight: 1.6,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textTransform: 'lowercase',

    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.body2RegularDesktop
    }
  },
  emailMobile: {
    ...extendedTypography.body1RegularDesktop,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  level: {
    ...extendedTypography.body2RegularDesktop,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.secondary.main
  },
  levelMobile: {
    ...extendedTypography.body1RegularDesktop,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.secondary.main
  },
  secondRow: {
    position: 'relative',
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2),
    alignItems: 'center',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      height: 0.5,
      backgroundColor: theme.palette.colors.dividerGrey
    }
  },
  points: {
    ...extendedTypography.body2RegularDesktop
  },
  pointsButton: {marginLeft: 'auto'},

  firstRowMobile: {
    width: '100%',
    position: 'relative',
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(1.5),
    justifyContent: 'start',
    padding: theme.spacing(1, 3, 1.5, 3),

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      height: 0.5,
      backgroundColor: theme.palette.colors.dividerGrey
    }
  },
  pointsMobile: {
    ...extendedTypography.body1RegularDesktop
  },
  secondRowMobile: {
    position: 'relative',
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5, 3),
    alignItems: 'center',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      height: 0.5,
      backgroundColor: theme.palette.colors.dividerGrey
    }
  }
}));

export const ProfileInfo = ({customer, isMobile}) => {
  const classes = useStyles();

  return (
    <div className={classes.profileInfo}>
      <div className={isMobile ? classes.firstRowMobile : classes.firstRow}>
        <UserLevelAvatar
          size={isMobile ? 'big' : 'small'}
          bg='#fff'
          customer={customer}
        />

        <div className={classes.firstRowText}>
          <Typography
            className={isMobile ? classes.emailMobile : classes.email}
          >
            {customer?.email}
          </Typography>
          <Typography className={classes.level}>
            {customer?.loyaltyDetails?.currentLevel.name}
          </Typography>
        </div>
      </div>

      <div className={isMobile ? classes.secondRowMobile : classes.secondRow}>
        <PointsStarIcon />
        <Typography
          className={isMobile ? classes.pointsMobile : classes.points}
        >
          {maybe(() => customer.loyaltyDetails.points, 0)} Points
        </Typography>

        {/*do not delete, needed in the future*/}
        {/*<Button*/}
        {/*  component='a'*/}
        {/*  href={`/${PageSlugs.LOYALTY_REWARDS_STORE}`}*/}
        {/*  variant='outlined'*/}
        {/*  className={clsx(classes.pointsButton, 'mobile')}*/}
        {/*  size={isMobile ? 'medium' : 'small'}*/}
        {/*  color='secondary'*/}
        {/*  rel='noopener noreferrer'*/}
        {/*  target='_blank'*/}
        {/*>*/}
        {/*  Buy Points*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

ProfileInfo.propTypes = {
  customer: PropTypes.object.isRequired,
  isMobile: PropTypes.bool
};
