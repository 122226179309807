import {useRouter} from 'next/router';
import Button from '@material-ui/core/Button';
import {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {LOGIN_MODAL} from 'src/config';
import useModal from 'src/hooks/useModal';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

export const useStyles = makeStyles(() => ({
  button: {
    ...extendedTypography.body1SemiboldDesktop,
    '&:hover': {opacity: 0.75}
  },
  buttonRounded: {
    borderRadius: 100
  }
}));

export const LoginButton = ({
  onClick,
  className,
  isMobile,
  size,
  rounded = false,
  /*children*/ ...props
}) => {
  const classes = useStyles();
  const {showModal} = useModal();
  const router = useRouter();
  const {signin} = router.query;

  useEffect(() => {
    if (signin) {
      showModal(LOGIN_MODAL);
    }
  }, [signin]);

  if (isMobile) {
    return (
      <Button
        variant='outlined'
        onClick={onClick}
        size={size}
        classes={{
          root: clsx(rounded && classes.buttonRounded, className)
        }}
        {...props}
      >
        LOGIN
      </Button>
    );
  }

  return (
    <Button
      onClick={onClick}
      classes={{root: clsx(classes.button, className)}}
      {...props}
    >
      LOGIN
    </Button>
  );
};

LoginButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};
