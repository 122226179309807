import {makeStyles} from '@material-ui/core/styles';
import {useState} from 'react';
import clsx from 'clsx';
import {ClickAwayListener, Paper, Popper, Fade} from '@material-ui/core';
import PropTypes from 'prop-types';

import useModal from 'src/hooks/useModal';
import {CUSTOM_MODAL} from 'src/config';

import {ProfileButton} from './ProfileButton';

import {ProfileMenu} from 'src/views/HeaderNext/components/ProfileMenu';
import {ProfileInfo} from 'src/views/HeaderNext/components/ProfileInfo';
import AuthLinksModal from 'src/views/Header/components/AccountsButton/AuthLinksModal';

export const POPPER_WIDTH = 256;

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    marginRight: 15,
    borderRadius: 8,
    padding: 4,
    width: 40,
    height: 40
  },
  popper: {
    zIndex: theme.zIndex.appBar - 2,
    width: POPPER_WIDTH
  },
  popperOpen: {
    zIndex: theme.zIndex.appBar - 2
  },
  paper: {
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.colors.cardItem,
    borderRadius: 4,
    color: theme.palette.common.white
  }
}));

export const ProfileInfoWithDropdown = ({customer}) => {
  const classes = useStyles();
  const {showModal} = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const handleToggle = e => {
    if (customer === null) {
      showModal(CUSTOM_MODAL, 'Account', <AuthLinksModal />);
      return;
    }

    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.profileWrapper}>
      <ProfileButton
        open={open}
        customer={customer}
        onClick={e => handleToggle(e)}
      />

      <Popper
        id={id}
        open={open}
        placement='bottom-end'
        anchorEl={anchorEl}
        transition
        disablePortal
        className={clsx(classes.popper, open && classes.popperOpen)}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper square size='small' classes={{root: classes.paper}}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <ProfileInfo customer={customer} />
                  <ProfileMenu
                    customer={customer}
                    onDropdownClose={handleClose}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

ProfileInfoWithDropdown.propTypes = {
  customer: PropTypes.object
};
