import makeStyles from '@material-ui/core/styles/makeStyles';
import {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Collapse} from '@material-ui/core';

import {Dropdown} from 'src/componentsNext/Dropdown';
import {currenciesMap} from 'src/core/constants';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import {SelectButton} from 'src/componentsNext/select/SelectButton';

import {CurrencyList} from 'src/views/HeaderNext/components/CurrencySwitcher/CurrencyList';

export const useStyles = makeStyles(theme => ({
  popper: {
    minWidth: 142,
    width: 'fit-content',
    zIndex: theme.zIndex.appBar + 1
  },
  popperInline: {
    zIndex: theme.zIndex.modal + 1
  },
  paper: {
    padding: ['2px 0', '!important'],
    borderRadius: [4, '!important'],

    '& .optionLabel': {
      ...extendedTypography.body1MediumDesktop,
      lineHeight: 1.2
    }
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginRight: -theme.spacing(1),
    ...extendedTypography.body1SemiboldDesktop
  },
  buttonLabelMobile: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginRight: -theme.spacing(1),
    ...extendedTypography.body2Medium
  },
  buttonDrawerLabel: {
    ...extendedTypography.body1SemiboldDesktop,
    justifyContent: 'space-between'
  },
  drawerButton: {
    padding: theme.spacing(1.5, 3)
  }
}));

export const CurrencySwitcher = ({
  currency,
  currencies,
  onChange,
  useInDrawer = false,
  isCustomer = false
}) => {
  const classes = useStyles();
  const [isListOpen, setIsListOpen] = useState(false);

  const currenciesOptions = useMemo(() => {
    return currencies.map(currency => {
      return {
        label: currenciesMap[currency].label,
        value: currenciesMap[currency].value,
        icon: currenciesMap[currency].icon
      };
    });
  }, [currencies]);

  const Icon = currenciesMap[currency].icon;

  const handleClick = () => {
    setIsListOpen(open => !open);
  };

  if (useInDrawer) {
    return (
      <div style={{width: '100%', marginBottom: !isCustomer ? 0 : 8}}>
        <SelectButton
          withoutBg
          label={
            <div className={classes.buttonLabelMobile}>
              <Icon width={20} height={20} />
              {currenciesMap[currency].label}
            </div>
          }
          labelClassName={classes.buttonDrawerLabel}
          className={classes.drawerButton}
          onClick={handleClick}
          open={isListOpen}
        />
        <Collapse in={isListOpen}>
          <div style={{marginBottom: 8}}>
            <CurrencyList
              currencies={currencies}
              currency={currency}
              onChange={onChange}
            />
          </div>
        </Collapse>
      </div>
    );
  }

  return (
    <div style={{width: 100}}>
      <Dropdown
        value={currency}
        options={currenciesOptions}
        onChange={onChange}
        buttonLabel={
          <div className={classes.buttonLabel}>
            <Icon width={20} height={20} />
            {currenciesMap[currency].shortLabel}
          </div>
        }
        PopperProps={{
          keepMounted: false,
          disablePortal: false,
          className: classes.popper
        }}
        PaperProps={{className: classes.paper}}
        dropdownComponentProps={{withoutBg: true}}
      />
    </div>
  );
};

CurrencySwitcher.propTypes = {
  currency: PropTypes.string.isRequired,
  currencies: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  useInDrawer: PropTypes.bool,
  isCustomer: PropTypes.bool
};
