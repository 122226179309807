import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {MenuItem} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

import {currenciesMap} from 'src/core/constants';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

export const useStyles = makeStyles(theme => ({
  menuItem: {
    minHeight: 36,
    padding: theme.spacing(0.5, 3),
    gap: theme.spacing(1),
    color: theme.palette.common.white,
    ...extendedTypography.body2Medium
  },
  menuItemSelected: {
    position: 'relative',
    color: theme.palette.secondary.main,
    // color: theme.palette.common.white,
    backgroundColor: theme.palette.backgrounds.menuItem,

    '&:before': {
      content: "''",
      width: 2,
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: theme.palette.secondary.main
    }
  }
}));

export const CurrencyList = ({currencies, currency, onChange}) => {
  const classes = useStyles();

  const currencyList = useMemo(() => {
    return currencies.map(currency => {
      return {
        label: currenciesMap[currency].label,
        value: currenciesMap[currency].value,
        icon: currenciesMap[currency].icon
      };
    });
  }, [currencies]);

  return (
    <>
      {currencyList.map((currencyItem, index) => {
        const Icon = currencyItem.icon;
        const label = currencyItem.label;
        const value = currencyItem.value;

        return (
          <MenuItem
            key={index}
            selected={currency === value}
            onClick={() => onChange(value)}
            classes={{
              root: clsx(
                classes.menuItem,
                currency === value && classes.menuItemSelected
              )
            }}
          >
            <Icon width={24} height={24} />
            {label}
          </MenuItem>
        );
      })}
    </>
  );
};

CurrencyList.propTypes = {
  currencies: PropTypes.array.isRequired
};
